<template>
  <div>
    <!-- Media storage -->
    <div class="mb-1 d-block font-weight-bolder text-nowrap">
      Media Storage
    </div>
    <b-card>
      <b-skeleton-table
        v-if="loadingMedia"
        :rows="1"
        :columns="4"
        :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
      />
      <div
        v-for="media in allUserMediaStorage"
        v-else
        :key="media.name"
        class="media-card p-1"
      >
        <div class="media-storage row">
          <div
            class="collapse-title col-3"
          >
            <b-media class="media pl-1">
              <template
                #aside
                class="div"
              >
                <b-img
                  class="userMediaImg"
                  :src="getThumbnailOfFile(media)"
                />
              </template>
              <span class="d-block font-weight-bolder text-nowrap id ">
                {{ media.name }}
              </span>
            </b-media>
          </div>
          <div
            class="collapse-title col-3"
          >
            <span class="d-block font-weight-bolder text-nowrap media float-right">
              {{ media.size }}
            </span>
          </div>
          <div
            class="collapse-title col-3"
          >
            <span class="d-block font-weight-bolder text-nowrap media float-right  ">
              {{ media.type }}
            </span>
          </div>
          <div
            class="collapse-title col-3"
          >
            <span
              class="d-block font-weight-bolder text-nowrap media float-right "
            >
              {{ media.import_from }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="allUserMediaStorage.length == 0 && loadingMedia=== false"
        class="id d-flex justify-content-center align-items-center"
      >
        No Media Storage Found
      </div>
      <div
        v-else
        class="buttonlink"
      >
        <b-button
          variant="link"
          class="p-0"
          @click="viewAllMediaStorage"
        >
          View All
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import {
  BCard, BMedia,
  BImg,
  BButton,
  BSkeletonTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import MediaType from '@/common/enums/mediaTypeEnum'
import { getThumbnailOfFile } from '@/common/global/functions'

Vue.use(Vuesax)

export default {
  components: {
    BCard,
    BMedia,
    BImg,
    BButton,
    BSkeletonTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      MediaType,
      allUserMediaStorage: [],
      loadingMedia: false,
      tableColumns: [
        { key: 'user', thStyle: 'display: none' },
        { key: 'size', thStyle: 'display: none' },
        { key: 'type', thStyle: 'display: none' },
        {
          key: 'display', thStyle: 'display: none',
        },
      ],
    }
  },
  async mounted() {
    await this.getUserMediaStorage()
  },
  methods: {
    getThumbnailOfFile(file) {
      return getThumbnailOfFile(file)
    },
    getUserMediaStorage() {
      this.loadingMedia = true
      this.$axios
        .get(`admin/media/${this.$route.params.userId}?offset=0&limit=${2}`)
        .then(({ data: { data } }) => {
          this.allUserMediaStorage = data.results
          this.loadingMedia = false
        }).catch(error => ({ error }))
    },

    viewAllMediaStorage() {
      this.$router.push(`/users/${this.$route.params.userId}/user-media-storage`)
    },
  },
}
</script>
<style scoped>
.media-card{
  border: none;
  margin-bottom: 1rem;
  box-shadow: 0px 3px 11.5px rgba(0,0,0,0.06) !important;
  border-radius: 7px;
}
.media{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin-left: 6px;
  margin-right: 6px;
}
.id{
  font-size: 14px;
  letter-spacing: 0px;
  color: #414141;
  font-weight: 600;
  font-family: "Montserrat";
  padding-left: 15px;
}
.buttonlink{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
}
.media-storage{
  display: flex;
  margin-right: 0px !important;
}
.userMediaImg{
  width: 20px;
  height: 20px;
}
</style>
