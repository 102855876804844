<template>
  <div>
    <!-- Devices -->
    <div class="mb-1 d-block font-weight-bolder text-nowrap">
      Devices
    </div>
    <b-card>
      <b-skeleton-table
        v-if="loadingDevices"
        :rows="1"
        :columns="2"
        :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
      />
      <div
        v-for="device in allDevices"
        v-else
        :key="device.id"
        class="profile p-1"
      >
        <div class="row">
          <div
            class="leads collapse-title col-10"
          >
            <span class="d-block font-weight-bolder text-nowrap id ">
              {{ device.name }}
            </span>
          </div>
          <div
            style="float: right"
            class="leads col-2"
          >
            <vs-switch
              :value="device.is_banner_ad_mode_enabled"
              :style="`background-color: ${!device.is_banner_ad_mode_enabled ? '#FF0000' : '#00E265'}`"
              disabled
              circle
              class="switchs"
              style="float: right"
            >
              <span
                slot="on"
                style="font-weight: bold"
              >Disable</span>
              <span
                slot="off"
                style="font-weight: bold"
              >Enable</span>
            </vs-switch>
          </div>
        </div>
      </div>
      <div
        v-if="allDevices.length === 0 && loadingDevices === false"
        class="id d-flex justify-content-center align-items-center"
      >
        No Devices Found
      </div>
      <div
        v-else
        class="buttonlink"
      >
        <b-button
          variant="link"
          class="p-0"
          @click="viewAllDevices"
        >
          View All
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import {
  BCard,
  BButton,
  BSkeletonTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

Vue.use(Vuesax)

export default {
  components: {
    BCard,
    BButton,
    BSkeletonTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      allDevices: [],
      loadingDevices: false,
    }
  },
  async mounted() {
    await this.getUserDevices()
  },
  methods: {
    getUserDevices() {
      this.loadingDevices = true
      this.$axios.get(`admin/device/${this.$route.params.userId}?offset=0&limit=${2}`)
        .then(({ data }) => {
          this.allDevices = data.data.results
          this.loadingDevices = false
        }).catch(() => this.$swal('Device not found!'))
    },
    viewAllDevices() {
      this.$router.push(`/users/${this.$route.params.userId}/user-devices-group`)
    },
  },
}
</script>
<style scoped>
.profile{
  border: none;
  margin-bottom: 1rem;
  box-shadow: 0px 3px 11.5px rgba(0,0,0,0.06) !important;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 7px;
}
.leads{
  width: 40%;
}
.switchs {
  width: 50px;
  padding-inline: 40px;
  border-radius: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.id{
  font-size: 14px;
  letter-spacing: 0px;
  color: #414141;
  font-weight: 600;
  font-family: "Montserrat";
  padding-left: 15px;
}
.buttonlink{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
}
</style>
