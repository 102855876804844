<template>
  <div>
    <!-- profiles -->
    <div class="mb-1 d-block font-weight-bolder text-nowrap">
      Group Name
    </div>
    <b-card
      id="group-card"
      class="p-2"
    >
      <b-skeleton-wrapper
        :loading="isGroupMembers"
      >
        <template #loading>
          <b-skeleton width="100%" />
          <b-skeleton width="75%" />
          <b-skeleton width="50%" />
        </template>
        <b-row class="inner-card-class">
          <b-col
            class="group pt-1 pb-1 mb-1"
          >
            <span
              class=" collapse-title"
            >
              <b-card
                align="center"
                class="cursor-pointer card-border mx-0 mx-md-2"
              >
                <div class="d-flex align-items-center w-80">
                  <div class="d-flex justify-content-start w-50">
                    <!-- img -->
                    <div>
                      <b-avatar
                        :src="(selectedGroupDetail && selectedGroupDetail.image) ? selectedGroupDetail.image : require('@/assets/images/avatars/Group-place-holder.svg')"
                        size="40"
                        alt="basic svg img"
                      />
                      <span
                        class="ml-1 pr-md-0 pr-1 align-items-center font-weight-bolder"
                      >
                        {{ (selectedGroupDetail && selectedGroupDetail.name) ? ucFirst(selectedGroupDetail.name): '' }}
                      </span>
                    </div>

                  </div>
                  <div class=" d-flex justify-content-end w-50 ">
                    <div>
                      <!-- avatar group -->
                      <b-avatar-group
                        v-if="selectedGroupDetail && selectedGroupDetail.user_group_has_members && selectedGroupDetail.user_group_has_members.length"
                        size="28"
                      >
                        <b-avatar
                          v-for="member in selectedGroupDetail.user_group_has_members.slice(0, 5)"
                          :key="(member.user && member.user.id) ? member.user.id : member.id"
                          v-b-tooltip.hover.bottom="(member.user && member.user.name) ? member.user.name : member.user.email"
                          :src="(member.user && member.user.image) ? member.user.image : require('@/assets/images/avatars/User-place-holder.svg')"
                          class="pull-up"
                        />
                        <p
                          v-if="selectedGroupDetail.user_group_has_members.length > 5"
                          class="align-self-center fw-500 cursor-pointer ml-1 mb-0"
                        >
                          .&nbsp;.&nbsp;.
                        </p>
                        <p class="align-self-center cursor-pointer ml-1 mb-0">
                          {{ (selectedGroupDetail.user_group_has_members.length - 1 > 0) ? selectedGroupDetail.user_group_has_members.length - 1 : '' }} members
                        </p>
                      </b-avatar-group>
                    </div>
                  </div>
                </div>
              </b-card>
            </span>
          </b-col>
        </b-row>
      </b-skeleton-wrapper>
      <div
        v-if="selectedGroupDetail.length == 0 && !isGroupMembers"
        class="id d-flex justify-content-center align-items-center"
      >
        No Media Storage Found
      </div>
      <div
        v-else
        class="button-link"
      >
        <b-button
          variant="link"
          class="p-0"
          @click="viewAllGroupMembers"
        >
          View All
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import {
  BCard,
  BAvatar,
  BSkeleton,
  BSkeletonWrapper,
  BRow,
  BCol,
  BAvatarGroup,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import MediaType from '@/common/enums/mediaTypeEnum'

Vue.use(Vuesax)

export default {
  components: {
    BCard,
    BAvatar,
    BSkeleton,
    BSkeletonWrapper,
    BRow,
    BCol,
    BAvatarGroup,
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      MediaType,
      selectedGroupDetail: [],
      isGroupMembers: false,
    }
  },
  async mounted() {
    await this.getUserGroups()
  },
  methods: {
    viewAllGroupMembers() {
      this.$router.push(`/users/${this.$route.params.userId}/user-group-members`)
    },
    ucFirst(val) {
      return val.replace(/(^|\s)[A-Za-zÀ-ÖØ-öø-ÿ]/g, c => c.toUpperCase())
    },
    async getUserGroups() {
      this.isGroupMembers = true
      try {
        const res = await this.$axios.get(`admin/user-has-group/get-user-group-details/${this.$route.params.userId}`)
        const { data: resData } = res || {}
        const { data } = resData || {}
        this.selectedGroupDetail = data || {}
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        this.$swal(message.toString())
        this.isGroupMembers = false
      }
      this.isGroupMembers = false
    },
  },
}
</script>
<style lang="scss">
#group-card {
  .card-body{
    padding: 0px !important;
  }
  .group {
    border: none;
    box-shadow: 0px 5px 11.5px rgba(0, 0, 0, 0.08) !important;
    border-radius: 6px;
    background-color: #ffffff;
    @media (max-width: 640px) {
      padding-left: 2px !important;
      padding-right: 2px !important;
    }
  }
  .button-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
  }
  .card-border{
    max-height: 47px !important;
    @media (max-width: 640px) {
      max-height: 100px !important;
    }
  }
  .inner-card-class{
    margin-left: 3px;
    margin-right: 3px;
    @media (max-width: 640px) {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }
}
</style>
